<template>
  <div class="HistoricalAnalysis">
    <div class="btn">
      <a-radio-group
        :value="datepickerType"
        defaultValue="day"
        @change="datepickerChange"
      >
        <a-radio-button value="day"> 天 </a-radio-button>
        <a-radio-button value="week">周</a-radio-button>
        <a-radio-button value="month">月</a-radio-button>
      </a-radio-group>
      <!-- <span>*{{ explainText }}</span> -->
    </div>
    <div class="datePick">
      <a-date-picker
        size="default"
        v-if="ShowType == 'dayShow'"
        @change="onChange"
        v-model="queryParam.find_time"
        :defaultValue="defaultValue"
        :disabled-date="disabledDate"
      />
      <!-- :disabled-date="disabledDate" -->

      <a-week-picker
        size="default"
        placeholder="请选择周"
        v-if="ShowType == 'weekShow'"
        @change="onChange"
        :defaultValue="defaultValueWeek"
        :disabled-date="disabledWeek"
      />
      <!-- :disabled-date="disabledDate" -->

      <a-month-picker
        size="default"
        placeholder="请选择月"
        v-if="ShowType == 'monthShow'"
        @change="onChange"
        :defaultValue="defaultValueMonth"
        :disabled-date="disabledMonth"
      />
      <!-- :disabled-date="disabledMonth" -->

      <a-button type="primary" class="searchBtn" @click="searchClick">
        查询
      </a-button>
    </div>
    <!-- 使用率展示 -->
    <div class="usageRate">
      <div class="Normal">
        <div class="title">
          <div>正常使用率</div>
          <div>
            <a-tooltip>
              <template slot="title">{{ NormalText }} </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </div>
        <div class="value">
          <div class="rate">{{ NormalNum }}%</div>
          <div :class="NormalNumChange < 0 ? 'green' : 'red'">
            {{ NormalNumChange < 0 ? "下降" : "上升"
            }}{{
              NormalNumChange < 0
                ? NormalNumChange.replace("-", "")
                : NormalNumChange
            }}%
          </div>
        </div>
      </div>
      <div class="valid">
        <div class="title">
          <div>有效使用率</div>
          <div>
            <a-tooltip>
              <template slot="title">{{ validText }} </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </div>
        <div class="value">
          <div class="rate">{{ validNumNum }}%</div>
          <div :class="validNumNumChange < 0 ? 'green' : 'red'">
            {{ validNumNumChange < 0 ? "下降" : "上升"
            }}{{
              validNumNumChange < 0
                ? validNumNumChange.replace("-", "")
                : validNumNumChange
            }}%
          </div>
        </div>
      </div>
      <div class="Long_termOnline">
        <div class="title">
          <div>长期在线使用率</div>
          <div>
            <a-tooltip>
              <template slot="title">{{ Long_termText }} </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </div>
        <div class="value">
          <div class="rate">{{ Long_termNum }}%</div>
          <div :class="Long_termNumChange < 0 ? 'green' : 'red'">
            {{ Long_termNumChange < 0 ? "下降" : "上升"
            }}{{
              Long_termNumChange < 0
                ? Long_termNumChange.replace("-", "")
                : Long_termNumChange
            }}%
          </div>
        </div>
      </div>
    </div>
    <!-- 统计图展示 -->
    <div class="echars"><div id="echars" style="height: 100%"></div></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getDcountbchistory } from "@/api/device";
import moment from "moment";
export default {
  name: "HistoricalAnalysis",
  props: {
    record: {},
  },
  data() {
    return {
      queryParam: {
        // bc_id: "",
        find_type: "1",
        find_time: "",
      },
      datepickerType: "day",
      ShowType: "dayShow",
      dateValue: {},
      explainText: "统计截止昨日18点数据",
      NormalText: "24小时内在线累计时长大于2小时的设备数量/18点已绑定数量",
      validText: "工作时间内在线累计时长大于2小时的设备数量/18点已绑定数量",
      Long_termText: "24小时在线累计时长大于23小时的设备数量/18点已绑定数量",
      // 统计图
      option: {
        tooltip: {
          // trigger: "item",
          trigger: "axis",
          //"{b}{c}个工地", //{b}：name; {c}:value
          formatter: function (params) {
            var result = "";
            result += params[0].name + "<br />";
            for (var i = 0; i < params.length; i++) {
              // result += params[i].name + "<br />";

              result +=
                params[i].seriesName + ": " + params[i].value + "%" + "<br />";
            }

            // result += params.name + "<br />";
            // result += params.seriesName + ": " + params.value + "<br />";

            // console.log(result);
            return result;
            // return params.seriesName;
          },
        },
        legend: {
          // Try 'horizontal'
          data: ["正常使用率", "有效使用率", "长期在线率"],
          // orient: "vertical",
          right: 20,
        },
        xAxis: {
          data: ["A", "B", "C", "D", "E"],
          boundaryGap: false, //坐标轴两边不留白
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value} %",
          },
          show: true,
        },
        series: [
          {
            name: "正常使用率",
            data: [10, 22, 28, 43, 49],
            type: "line",
            // stack: "x",
            // stack: "Total",
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: "top",
            //       formatter: "{b}\n{c}%",
            //     },
            //   },
            // },
          },
          {
            name: "有效使用率",
            data: [5, 20, 3, 40, 10],
            type: "line",
            // stack: "Total",
          },
          {
            name: "长期在线率",
            data: [15, 4, 3, 5, 10],
            type: "line",
            // stack: "Total",
          },
        ],
      },
      NormalNum: "", //正常使用率
      NormalNumChange: "", //正常使用率变化值
      validNumNum: "", //有效使用率
      validNumNumChange: "", //有效使用率变化值
      Long_termNum: "", //长期在线使用率
      Long_termNumChange: "", //长期在线使用率变化值
      defaultValue: "",
      defaultValueWeek: "",
      defaultValueMonth: "",
    };
  },
  watch: {
    record(nValue) {
      console.log(nValue);
      //   this.datepickerType = "day";
    },
    datepickerType(nValue) {
      if (nValue == "day") {
        this.explainText = "统计截止昨日18点数据";
        this.ShowType = "dayShow";
        // this.Normal_day =
        //   "24小时内在线累计时长大于2小时的设备数量/昨天18点已绑定数量";
      }
    },
  },
  created() {
    this.queryParam.bc_id = this.record.bc_id + "";
    let today = new Date(new Date()).getTime(); //当天24点的时间戳;
    let yesterday = today - 1000 * 60 * 60 * 24;
    // console.log(this.$Format(today / 1000));
    this.defaultValue = moment(yesterday);
    // console.log(this.$Format(yesterday / 1000));
    // console.log(this.defaultValue);
    let defaultValueWeek = today - 1000 * 60 * 60 * 24 * 7;
    this.defaultValueWeek = moment(defaultValueWeek);
    let defaultValueMonth = today - 1000 * 60 * 60 * 24 * 30;
    this.defaultValueMonth = moment(defaultValueMonth);
  },
  mounted() {
    this.getData();
  },

  methods: {
    // 禁止选中
    disabledDate(current) {
      // return current && current < moment().endOf('day') //官方方法禁用今日及之前日期
      // return current < moment().subtract(1, "day"); //1 改为0为包括今日禁用，今天之后日期禁用
      return current > moment().subtract(1, "day"); //1 改为0为包括今日禁用，今天之前日期禁用
    },
    disabledWeek(current) {
      // Can not select days before today and today
      // return current && current > moment().endOf(30, "day");
      return current && current >= moment().startOf("week");
    },
    disabledMonth(current) {
      return current && current > moment().subtract(1, "days").endOf("day");
    },

    datepickerChange(e) {
      // console.log(e.target.value);
      this.dateValue = {};
      this.datepickerType = e.target.value;
      this.ShowType = e.target.value + "Show";
      if (e.target.value == "day") {
        this.queryParam.find_type = "1";
        this.explainText = "统计截止昨日18点数据";
        // 使用率提示
        this.NormalText =
          "24小时内在线累计时长大于2小时的设备数量/18点已绑定数量";
        this.validText =
          "工作时间内在线累计时长大于2小时的设备数量/18点已绑定数量";
        this.Long_termText =
          "24小时在线累计时长大于23小时的设备数量/18点已绑定数量";
      } else if (e.target.value == "week") {
        this.queryParam.find_type = "2";
        this.explainText = "统计截止上一周平均数据";
        // 使用率提示
        this.NormalText = "每周7天的累计相加/7";
        this.validText = "每周7天的累计相加/7";
        this.Long_termText = "每周7天的累计相加/7";
      } else if (e.target.value == "month") {
        this.queryParam.find_type = "3";
        this.explainText = "统计截止上一月平均数据";
        // 使用率提示
        this.NormalText = "每个自然月平均正常使用率";
        this.validText = "每个自然月平均正常使用率";
        this.Long_termText = "每个自然月平均正常使用率";
      }
    },
    // 获取的选取日期的值
    onChange(date, dateString) {
      this.dateValue = Date.parse(date) / 1000;
      console.log("dateValue", this.dateValue);
    },
    // 查询按钮
    searchClick() {
      // 获取时间戳
      // console.log(Object.keys(this.dateValue).length == 0);
      // console.log(JSON.stringify(this.dateValue) === "{}");

      this.queryParam.find_time =
        JSON.stringify(this.dateValue) === "{}" ? "" : this.dateValue;
      console.log(this.queryParam.find_time);
      this.getData();
    },
    isOnlyClickedOneIsUnSelected(name, selected) {
      var unSelectedCount = 0;
      for (item in selected) {
        if (!selected.hasOwnProperty(item)) {
          continue;
        }
        if (selected[item] == false) {
          ++unSelectedCount;
          console.log(unSelectedCount);
        }
      }
      return unSelectedCount == 1 && selected[name] == false;
    },
    onlyEnableCurrentSelectedLegend(name, selected, echartInstance) {
      var legend = [];
      for (item in selected) {
        if (!selected.hasOwnProperty(item)) {
          continue;
        }
        legend.push({ name: item });
        echartInstance.dispatchAction({
          type: "legendToggleSelect",
          batch: legend,
        });
      }
    },
    // 获取数据
    async getData() {
      let that = this;
      var myChart = echarts.init(document.getElementById("echars"));
      const orderParam = ["bc_id", "find_type", "find_time"];
      // this.queryParam.bc_id = this.record.bc_id;
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await getDcountbchistory(params)
        .then((res) => {
          // console.log(res.data);
          let data = res.data;
          let bind_num7 = data.bind_num7 == 0 ? 1 : data.bind_num7;
          let bind_num6 = data.bind_num6 == 0 ? 1 : data.bind_num6;
          let bind_num5 = data.bind_num5 == 0 ? 1 : data.bind_num5;
          let bind_num4 = data.bind_num4 == 0 ? 1 : data.bind_num4;
          let bind_num3 = data.bind_num3 == 0 ? 1 : data.bind_num3;
          let bind_num2 = data.bind_num2 == 0 ? 1 : data.bind_num2;
          let bind_num1 = data.bind_num1 == 0 ? 1 : data.bind_num1;
          this.option.xAxis.data = [
            data.time7,
            data.time6,
            data.time5,
            data.time4,
            data.time3,
            data.time2,
            data.time1,
          ];
          this.option.series[0].data = [
            Math.floor((data.normal_use7 / bind_num7) * 10000) / 100,
            Math.floor((data.normal_use6 / bind_num6) * 10000) / 100,
            Math.floor((data.normal_use5 / bind_num5) * 10000) / 100,
            Math.floor((data.normal_use4 / bind_num4) * 10000) / 100,
            Math.floor((data.normal_use3 / bind_num3) * 10000) / 100,
            Math.floor((data.normal_use2 / bind_num2) * 10000) / 100,
            Math.floor((data.normal_use1 / bind_num1) * 10000) / 100,
          ];
          // 正常使用率
          // this.NormalNum = (data.normal_use1 / bind_num1).toFixed(4) * 100;
          this.NormalNum =
            Math.floor((data.normal_use1 / bind_num1) * 10000) / 100;
          // let pre_NormalNum = (data.normal_use2 / bind_num2).toFixed(4) * 100;
          let pre_NormalNum =
            Math.floor((data.normal_use2 / bind_num2) * 10000) / 100;
          this.NormalNumChange =
            Math.floor((this.NormalNum - pre_NormalNum) * 100) / 100 + "";
          this.option.series[1].data = [
            Math.floor((data.effective_use7 / bind_num7) * 10000) / 100,
            Math.floor((data.effective_use6 / bind_num6) * 10000) / 100,
            Math.floor((data.effective_use5 / bind_num5) * 10000) / 100,
            Math.floor((data.effective_use4 / bind_num4) * 10000) / 100,
            Math.floor((data.effective_use3 / bind_num3) * 10000) / 100,
            Math.floor((data.effective_use2 / bind_num2) * 10000) / 100,
            Math.floor((data.effective_use1 / bind_num1) * 10000) / 100,
          ];
          //有效使用率
          // this.validNumNum = (data.effective_use1 / bind_num1).toFixed(4) * 100;
          // let pre_validNumNum =
          //   (data.effective_use2 / bind_num2).toFixed(4) * 100;
          // this.validNumNumChange = (this.validNumNum - pre_validNumNum).toFixed(
          //   2
          // );

          this.validNumNum =
            Math.floor((data.effective_use1 / bind_num1) * 10000) / 100;
          let pre_validNumNum =
            Math.floor((data.effective_use2 / bind_num2) * 10000) / 100;
          this.validNumNumChange =
            Math.floor((this.validNumNum - pre_validNumNum) * 100) / 100 + "";
          this.option.series[2].data = [
            Math.floor((data.long_use7 / bind_num7) * 10000) / 100,
            Math.floor((data.long_use6 / bind_num6) * 10000) / 100,
            Math.floor((data.long_use5 / bind_num5) * 10000) / 100,
            Math.floor((data.long_use4 / bind_num4) * 10000) / 100,
            Math.floor((data.long_use3 / bind_num3) * 10000) / 100,
            Math.floor((data.long_use2 / bind_num2) * 10000) / 100,
            Math.floor((data.long_use1 / bind_num1) * 10000) / 100,
          ];
          //长期在线使用率
          // this.Long_termNum = (data.long_use1 / bind_num1).toFixed(4) * 100;
          // let pre_Long_termNum = (data.long_use2 / bind_num2).toFixed(4) * 100;
          // this.Long_termNumChange = (
          //   this.Long_termNum - pre_Long_termNum
          // ).toFixed(2);

          this.Long_termNum =
            Math.floor((data.long_use1 / bind_num1) * 10000) / 100;
          let pre_Long_termNum =
            Math.floor((data.long_use2 / bind_num2) * 10000) / 100;
          this.Long_termNumChange =
            Math.floor((this.Long_termNum - pre_Long_termNum) * 100) / 100 + "";
        })
        .then(() => {});
      // 绘制图表
      myChart.on("legendselectchanged", function (obj) {
        var selected = obj.selected;
        var name = obj.name;
        if (selected != undefined) {
          if (that.isOnlyClickedOneIsUnSelected(name, selected)) {
            // all legend are selected except current clicked one
            that.onlyEnableCurrentSelectedLegend(name, selected, myChart);
          }
        }
      });
      myChart.setOption(this.option);
    },
  },
};
</script>

<style lang="less" scoped>
.HistoricalAnalysis {
  .btn {
    margin: 0 0 10px 0;
  }
  .datePick {
    .searchBtn {
      margin-left: 5px;
    }
  }
  .usageRate {
    margin-top: 10px;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-around;
    .Normal,
    .valid,
    .Long_termOnline {
      width: 32%;
      padding: 5px;
      .title {
        display: flex;
        justify-content: space-between;
      }
      .value {
        text-align: center;
        .rate {
          font-size: 40px;
          font-weight: 800;
        }
      }
    }
    .Normal {
      background-color: #eaf5ff;
      border-bottom: 10px solid #5792cc;
    }
    .valid {
      background-color: #ebfcf7;
      border-bottom: 10px solid #67c4aa;
    }
    .Long_termOnline {
      background-color: #fef6ec;
      border-bottom: 10px solid #e3b278;
    }
  }
  .echars {
    margin-top: 10px;
    width: 100%;
    height: 600px;
    // background-color: pink;
  }
  #echars {
    width: 100%;
  }
}
.green {
  color: green;
  font-weight: 800;
}
.red {
  color: red;
  font-weight: 800;
}
</style>
