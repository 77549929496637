<template>
  <!-- 设备使用统计-按组织统计 -->
  <div style="padding-top: 20px">
    <div class="table_top">
      <div>
        设备总数=设备已绑定总数+设备未绑定总数；设备绑定总数=已绑定在线设备数+已绑定离线设备数
      </div>
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="
            isShowBtns.indexOf('DeviceStatistics-/api/count/dcountbcexport') >
            -1
          "
          >导出</a-button
        >
        <columns-set
          tableName="OrganizationalStatistics"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>

    <s-table
      :expandedRowKeys="expandedRowKeys"
      @expand="expand"
      ref="table"
      size="default"
      :columns="tableColumns"
      rowKey="bcName"
      :data="loadData"
      :showPagination="false"
      :pagination="false"
      :scroll="{ x: 800, y: 'calc(82vh - 8px )' }"
    >
      <!-- -->
      <!-- <span slot="cs_bossname" slot-scope="text, record">
        {{ text.cs_bossname }}
      </span> -->
      <template slot="bcIdBindFilterIcon">
        <span class="bcIdBindFilterIcon">
          <a-tooltip>
            <template slot="title"
              >实时在线查询所有绑定工地的设备数量
            </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>

      <span slot="bcIdBind" slot-scope="text, record" class="numValue">
        <span @click="goDeviceList(record, '2', '0')" class="cursorPointer">
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.bcIdSum != 0
              ? ((text / record.bcIdSum) * 100).toFixed(0)
              : 0
          }}%</span
        >
      </span>
      <template slot="bcIdOnlineFilterIcon">
        <span class="bcIdOnlineFilterIcon">
          <a-tooltip>
            <template slot="title">实时已绑定在线设备数量 </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <span slot="bcIdOnline" slot-scope="text, record" class="numValue">
        <span @click="goDeviceList(record, '2', '1')" class="cursorPointer">
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.bcIdBind != 0
              ? ((text / record.bcIdBind) * 100).toFixed(0)
              : 0
          }}%</span
        >
      </span>
      <template slot="bcIdOfflineFilterIcon">
        <span class="bcIdOfflineFilterIcon">
          <a-tooltip>
            <template slot="title">实时已绑定离线设备数量 </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <span slot="bcIdOffline" slot-scope="text, record" class="numValue">
        <span @click="goDeviceList(record, '2', '2')" class="cursorPointer">
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.bcIdBind != 0
              ? ((text / record.bcIdBind) * 100).toFixed(0)
              : 0
          }}%</span
        >
      </span>
      <template slot="bcIdUnBindFilterIcon">
        <span class="bcIdUnBindFilterIcon">
          <a-tooltip>
            <template slot="title">目前未绑定工地实时摄像头数量 </template>
            <a-icon type="question-circle" class="ws_nameTitle_icon" />
          </a-tooltip>
        </span>
      </template>
      <span slot="bcIdUnBind" slot-scope="text, record" class="numValue">
        <span @click="goDeviceList(record, '1', '0')" class="cursorPointer">
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.bcIdSum != 0
              ? ((text / record.bcIdSum) * 100).toFixed(0)
              : 0
          }}%</span
        >
      </span>
      <span slot="action" slot-scope="text, record">
        <span
          v-if="
            record.cs_id == '0' &&
            isShowBtns.indexOf('DeviceStatistics-/api/count/dcountbchistory') >
              -1
          "
          @click="showLinechart(record)"
          class="a_lineChart"
        >
          <a-icon type="line-chart" />历史分析</span
        >
      </span>
    </s-table>
    <!-- 历史分析弹出框 -->
    <a-modal
      :title="modalTitle"
      :visible="visibleHistory"
      width="80%"
      @ok="handleOkHistory"
      @cancel="handleCancelHistory"
      :footer="null"
    >
      <HistoricalAnalysis
        :record="record"
        ref="datepickerType"
        v-if="showHistory"
      />
    </a-modal>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { STable, ColumnsSet } from "@/components";
import { ExportDcountbc } from "@/api/export";
import { GetCompaniesStore, getDcountbc } from "@/api/device";
import HistoricalAnalysis from "./HistoricalAanalysis.vue";
const columns = [
  {
    title: "组织名称",
    dataIndex: "bcName",
    key: "bcName",
    width: "200px",
  },
  {
    title: "设备总数",
    dataIndex: "bcIdSum",
    width: "90px",
    align: "center",
    // sorter: (a, b) => a.bcIdSum - b.bcIdSum,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "设备绑定总数",
    dataIndex: "bcIdBind",
    width: "150px",
    scopedSlots: {
      customRender: "bcIdBind",
      filterIcon: "bcIdBindFilterIcon",
    },
    filterDropdown: " ",
    sorter: (a, b) => a.bcIdBind - b.bcIdBind,
    align: "center",
  },
  {
    title: "在线设备数",
    dataIndex: "bcIdOnline",
    width: "120px",
    scopedSlots: {
      customRender: "bcIdOnline",
      filterIcon: "bcIdOnlineFilterIcon",
    },
    filterDropdown: " ",
    sorter: (a, b) => a.bcIdOnline - b.bcIdOnline,
    align: "center",
  },
  {
    title: "离线设备数",
    width: "120px",
    dataIndex: "bcIdOffline",
    scopedSlots: {
      customRender: "bcIdOffline",
      filterIcon: "bcIdOfflineFilterIcon",
    },
    filterDropdown: " ",
    sorter: (a, b) => a.bcIdOffline - b.bcIdOffline,
    align: "center",
  },
  {
    title: "设备未绑定总数",
    dataIndex: "bcIdUnBind",
    scopedSlots: {
      customRender: "bcIdUnBind",
      filterIcon: "bcIdUnBindFilterIcon",
    },
    filterDropdown: " ",
    sorter: (a, b) => a.bcIdUnBind - b.bcIdUnBind,
    width: "150px",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "100px",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

const orderParam = ["bc_id", "bc_name", "cs_data"];
export default {
  name: "OrganizationalStatistics",
  components: {
    STable,
    ColumnsSet,
    HistoricalAnalysis,
  },
  data() {
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      expandedRowKeys: [],

      CompanystoreList: [], //设备分公司名称
      // 弹出框
      visible: false,
      edit: false,
      // visible: true,
      // edit: true,
      DrawerTitle: "",
      // 编辑
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      other: "",
      record: {},
      drawerType: "",
      deletRecord: [],
      // 历史分析弹出框
      modalTitle: "",
      visibleHistory: false,
      showHistory: false,
      // confirmLoading: false,
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
  },
  watch: {},
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  computed: {},
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("OrganizationalStatisticsColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    //
    loadData(parameter) {
      // const param = Object.assign({}, parameter, this.queryParam);
      // const requestParameters = this.$order(param, orderParam);
      // const params = {
      //   ...requestParameters,
      //   verify: this.$verify(requestParameters, orderParam),
      // };
      return getDcountbc().then((res) => {
        let data = res.data.bc_data;
        // console.log(data);
        let valueList = [];
        let children = [];
        data.forEach((item) => {
          let children2 = [];
          if (item.children.length > 0) {
            item.children.forEach((el) => {
              children2.push({
                bcName: el.csName,
                bc_id: el.cs_id,
                bcIdBind: el.bcCsIdBind,
                type: "门店",
                bcIdOnline: el.bcCsIdOnline,
                bcIdOffline: el.bcCsIdOffline,
                bcIdSum: el.bcCsIdSum,
                bcIdUnBind: el.bcCsIdUnBind,
                cs_id: item.bc_id + "-" + el.cs_id,
              });
            });
            children.push({
              bcName: item.bcName,
              bc_id: item.bc_id,
              type: "分公司",
              bcIdSum: item.bcIdSum,
              bcIdBind: item.bcIdBind,
              bcIdOnline: item.bcIdOnline,
              bcIdOffline: item.bcIdOffline,
              bcIdUnBind: item.bcIdUnBind,
              children: children2,
              cs_id: "0",
            });
          } else {
            children.push({
              bcName: item.bcName,
              bc_id: item.bc_id,
              type: "分公司",
              bcIdSum: item.bcIdSum,
              bcIdBind: item.bcIdBind,
              bcIdOnline: item.bcIdOnline,
              bcIdOffline: item.bcIdOffline,
              bcIdUnBind: item.bcIdUnBind,
              cs_id: "0",
              // children: [{ bcName: item.children.csName }],
            });
          }
        });
        valueList.push({
          bcName: res.data.zName,
          bc_id: res.data.z_id,
          bcIdSum: res.data.sumBc,
          bcIdBind: res.data.sumbindBc,
          bcIdOnline: res.data.sumOnlineBc,
          bcIdOffline: res.data.sumOfflineBc,
          bcIdUnBind: res.data.sumUnBindBc,
          children: children,
          cs_id: "0",
        });
        // console.log(valueList);
        // console.log(res);
        // 默认展开父节点
        this.expandedRowKeys.push(res.data.zName);
        let resData = {};
        valueList[0].children = valueList[0].children.sort(
          (a, b) => b.bcIdSum - a.bcIdSum
        );
        // console.log(valueList[0].children);
        resData.data = valueList;

        return resData;
      });
    },
    // 默认展开父节点时， // 默认行全部展开后，展开收起事件失效，需要手动监听展开收起事件
    expand(e, item) {
      e = !e;
      if (e) {
        this.expandedRowKeys.splice(
          this.expandedRowKeys.findIndex((i) => i == item.bcName),
          1
        );
      } else {
        this.expandedRowKeys.push(item.bcName);
      }
    },
    // 打开历史分析
    showLinechart(record) {
      // console.log(record);
      this.record = record;
      this.visibleHistory = true;
      this.showHistory = true;
      this.modalTitle = record.bcName + "-截止昨天，设备使用历史分析";
    },
    handleOkHistory(e) {
      this.confirmLoading = true;
      setTimeout(() => {
        this.visibleHistory = false;
        // this.confirmLoading = false;
        this.showHistory = false;
      }, 200);
    },
    handleCancelHistory(e) {
      this.visibleHistory = false;
      this.showHistory = false;
      this.$refs.datepickerType.datepickerType = "day";
    },
    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      console.log("ok");
      this.confirmExportLoading = true;
      const params = {
        // bc_name: this.queryParam.bc_name,
      };
      ExportDcountbc(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "设备统计-按组织统计.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },
    exportCancel() {
      this.visibleExport = false;
    },
    // 跳转到设备列表页面  参数依次为 整条信息  设备绑定状态   设备状态
    goDeviceList(record, d_bindstatus, d_status) {
      // console.log(record, d_bindstatus, d_status);
      localStorage.setItem("path", "DeviceList");
      let bc_id = record.bc_id;
      let cs_id = record.cs_id;
      console.log(bc_id, cs_id);
      this.$router.push({
        path: "/DeviceList",
        query: {
          BcCs_id: [cs_id == "0" ? bc_id : "-1", cs_id != "0" ? cs_id : ""],
          bc_id: cs_id == "0" ? bc_id : "",
          cs_id: cs_id != "0" ? cs_id.split("-")[1] : "",
          d_bindstatus: d_bindstatus,
          d_status: d_status,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.text-btn + .text-btn {
  margin-left: 16px;
}
.danger {
  color: #ff4d4f;
}
.select {
  width: 100%;
}
.editName {
  // margin-top: 200px;
  width: 50%;
  // background-color: pink;
  // /deep/.ant-select {
  //   width: 90%;
  // }
}
.deletStyle {
  color: red;
  margin-left: 10px;
}
.a_lineChart {
  color: #1890ff;
  // color: #1890ff;
  cursor: pointer;
}
/deep/ .ws_nameTitle_icon {
  width: 30px;
  height: 30px;
  display: block;
  margin-top: 22px;
  // margin-left: -100%;
  // margin-left: -70px;
  font-size: 18px;
}
.numValue {
  // color: blue;
  color: #1890ff;
  .cursorPointer {
    cursor: pointer;
  }
  .percent {
    margin-left: 20px;
    color: #1890ff;
    background-color: #dbedfc;
    padding: 0 10px;
    border-radius: 10px;
  }
}
.table_top {
  display: flex;
  color: gray;
  justify-content: space-between;
}
</style>
