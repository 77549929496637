<template>
  <div class="all">
    <div class="buttons">
      <a-button
        class="btn"
        @click="showStatistics('Oranzational')"
        :type="showOranzational == true ? 'primary' : ''"
        >按组织统计</a-button
      >
      <a-button
        class="btnPrimary"
        @click="showStatistics('principal')"
        :type="showOranzational == false ? 'primary' : ''"
        >按负责人统计</a-button
      >
    </div>
    <!-- 按组织统计 -->
    <div v-if="showOranzational">
      <OrganizationalStatistics />
    </div>
    <!-- 按负责人统计 -->
    <div v-if="!showOranzational">
      <PrincipalStatistics />
    </div>
  </div>
</template>

<script>
import OrganizationalStatistics from "./OrganizationalStatistics.vue";
import PrincipalStatistics from "./PrincipalStatistics.vue";
export default {
  name: "DeviceStatistics",
  components: { OrganizationalStatistics, PrincipalStatistics },
  data() {
    // this.columns = columns;
    return {
      showOranzational: true,
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    showStatistics(type) {
      if (type == "Oranzational") {
        this.showOranzational = true;
      } else {
        this.showOranzational = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.all {
  padding-top: 5px;
}
.buttons {
  text-align: left;
  width: 500px;
  //   background-color: pink;
  .btn {
    width: 140px;
    height: 50px;
    // border: 1px solid #40a9ff;
    border-radius: 0;
  }
  .btnPrimary {
    width: 165px;
    height: 50px;
    // border: 1px solid #40a9ff;
    border-radius: 0;
  }
}
</style>
