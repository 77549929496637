<template>
  <div>
    <!-- 设备使用统计-按负责人统计 -->
    <s-search :queryItems="queryItems" @fetchData="fetchUserAttend"></s-search>
    <div class="table_top">
      <div>*默认摄像头实时数量，负责人包括（项目经理、工程管家）</div>
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="
            isShowBtns.indexOf(
              'DeviceStatistics-/api/count/dcountwbossexport'
            ) > -1
          "
          >导出</a-button
        >
        <columns-set
          tableName="PrincipalStatistics"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <a-table
      ref="table"
      size="default"
      bordered
      rowKey="key"
      :columns="tableColumns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1500, y: 'calc(82vh - 110px )' }"
    >
      <!-- <span slot="serial" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + index + 1 }}
      </span> -->
      <span slot="负责工地数" class="TitleFlex"
        ><div>负责工地数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(1, 2, 'wsSumUpBlue')"
            :class="wsSumUpBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(1, 1, 'wsSumDownBlue')"
            :class="wsSumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="绑定设备工地数" class="TitleFlex"
        ><div>绑定设备工地数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(2, 2, 'wsBindUpBlue')"
            :class="wsBindUpBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(2, 1, 'wsBindDownBlue')"
            :class="wsBindDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="在线设备数" class="TitleFlex"
        ><div>在线设备数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(3, 2, 'wsOnlineUpBlue')"
            :class="wsOnlineUpBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(3, 1, 'wsOnlineDownBlue')"
            :class="wsOnlineDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="离线设备数" class="TitleFlex"
        ><div>离线设备数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(4, 2, 'wsOfflineUpBlue')"
            :class="wsOfflineUpBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(4, 1, 'wsOfflineDownBlue')"
            :class="wsOfflineDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="未绑定工地数" class="TitleFlex"
        ><div>未绑定工地数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(5, 2, 'wsUnBindUpBlue')"
            :class="wsUnBindUpBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(5, 1, 'wsUnBindDownBlue')"
            :class="wsUnBindDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="w_post" slot-scope="text">
        {{ text == "1" ? "工程管家" : "项目经理" }}
      </span>
      <span slot="action" slot-scope="text, record">
        <span @click="showLinechart(record)" class="a_lineChart">
          <a-icon type="line-chart" />历史分析</span
        >
      </span>
      <span
        slot="wsSum"
        slot-scope="text, record"
        class="numValue cursorPointer"
        @click="goToSiteList('负责工地数', record, '0')"
      >
        {{ text }}
      </span>
      <span slot="wsBind" slot-scope="text, record" class="numValue">
        <span
          class="cursorPointer"
          @click="goToSiteList('负责工地数', record, '2')"
        >
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.wsSum != 0 ? ((text / record.wsSum) * 100).toFixed(0) : 0
          }}%
        </span>
      </span>
      <span slot="wsOnline" slot-scope="text, record" class="numValue">
        <span class="cursorPointer" @click="goToDeviceList(record, '2', '1')">
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.wsBind != 0 ? ((text / record.wsBind) * 100).toFixed(0) : 0
          }}%
        </span>
      </span>
      <span slot="wsOffline" slot-scope="text, record" class="numValue">
        <span class="cursorPointer" @click="goToDeviceList(record, '2', '2')">
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.wsBind != 0 ? ((text / record.wsBind) * 100).toFixed(0) : 0
          }}%
        </span>
      </span>
      <span slot="wsUnBind" slot-scope="text, record" class="numValue">
        <span
          class="cursorPointer"
          @click="goToSiteList('未绑定工地数', record, '1')"
        >
          {{ text }}</span
        >
        <span class="percent">
          {{
            record.wsSum != 0 ? ((text / record.wsSum) * 100).toFixed(0) : 0
          }}%
        </span>
      </span>
      <span
        slot="action"
        slot-scope="text, record"
        v-if="
          isShowBtns.indexOf('DeviceStatistics-/api/count/dcountwbosshistory') >
          -1
        "
      >
        <span @click="showLinechart(record)" class="a_lineChart">
          <a-icon type="line-chart" />历史分析</span
        >
      </span>
    </a-table>
    <a-pagination
      size="middle"
      v-model="current"
      :pageSize="pageSize"
      @change="pageNumberChange"
      @showSizeChange="sizeChange"
      :pageSizeOptions="sizeList"
      :total="total"
      showSizeChanger
      style="margin: 20px 0; text-align: right"
    />
    <div>
      <!-- <a-descriptions bordered>
        <a-descriptions-item label="合计">
          Cloud Database Cloud Database Cloud Database Cloud Database
        </a-descriptions-item>
      </a-descriptions> -->
    </div>
    <!-- 历史分析弹出框 -->
    <a-modal
      :title="modalTitle"
      :visible="visibleHistory"
      width="80%"
      @ok="handleOkHistory"
      @cancel="handleCancelHistory"
      :footer="null"
    >
      <HistoricalAanalysisPrin
        :record="record"
        ref="datepickerType"
        v-if="visibleHistory"
      />
    </a-modal>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { getDcountwboss } from "@/api/device.js";
import { SSearch, ColumnsSet } from "@/components";
import { getWscountbc, getWscountboss } from "@/api/dataStatistics.js";
import HistoricalAanalysisPrin from "./HistoricalAanalysisPrin.vue";
import { ExportDcountwbosse } from "@/api/export";

// 搜索条件
const queryItems = [
  { label: "负责人姓名", value: "w_name", type: "input", defaultValue: "" },
  {
    label: "岗位",
    value: "w_post",
    type: "select",
    selectOption: "DeviceWorkPost",
  },
  {
    label: "分公司名称",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
  },
];
const columns = [
  // {
  //   title: "序号",
  //   width: "70px",
  //   scopedSlots: { customRender: "serial" },
  //   dataIndex: "serial",
  //   align: "center",
  // },

  {
    title: "负责人姓名",
    width: "150px",
    dataIndex: "wName",
    scopedSlots: { customRender: "w_name" },
    align: "center",
    fixed: "left",
  },
  {
    title: "岗位",
    width: "100px",
    dataIndex: "w_post",
    scopedSlots: { customRender: "w_post" },
    align: "center",
  },
  {
    title: "分公司",
    // width: "160px",
    dataIndex: "bcName",
    align: "center",
  },

  {
    // title: "负责工地数",
    width: "150px",
    dataIndex: "wsSum",
    align: "center",
    slots: { title: "负责工地数" },
    scopedSlots: { customRender: "wsSum" },
  },
  {
    // title: "绑定设备工地数",
    width: "180px",
    dataIndex: "wsBind",
    align: "center",
    slots: { title: "绑定设备工地数" },
    scopedSlots: { customRender: "wsBind" },
  },
  {
    // title: "在线设备数",
    width: "150px",
    dataIndex: "wsOnline",
    align: "center",
    slots: { title: "在线设备数" },
    scopedSlots: { customRender: "wsOnline" },
  },
  {
    // title: "离线设备数",
    width: "150px",
    dataIndex: "wsOffline",
    align: "center",
    slots: { title: "离线设备数" },
    scopedSlots: { customRender: "wsOffline" },
  },
  {
    // title: "未绑定工地数",
    width: "160px",
    dataIndex: "wsUnBind",
    align: "center",
    slots: { title: "未绑定工地数" },
    scopedSlots: { customRender: "wsUnBind" },
  },
  {
    title: "工地绑定设备数",
    width: "160px",
    dataIndex: "dBindSum",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "150px",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const orderParam = ["w_name", "bc_id", "w_post", "sort_column", "sort_rule"];
// const orderParam = [
//   "w_name",
//   "bc_id",
//   "w_post",
//   "find_type",
//   "find_time",
//   "sort_column",
//   "sort_rule",
//   "pageNo",
//   "pageSize",
// ];
export default {
  name: "PrincipalStatistics",
  components: {
    SSearch,
    ColumnsSet,
    HistoricalAanalysisPrin,
  },
  data() {
    this.columns = columns;

    return {
      queryParam: {
        sort_column: 1,
        sort_rule: 1,
      },
      tableColumns: [],
      dataSource: [],
      sizeList: ["10", "20", "30", "40"], //一页能显示条数
      pageSize: 10, //当前页显示多少条
      current: 1, //当前页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      record: {},

      wsSumUpBlue: false,
      wsSumDownBlue: false,
      wsBindUpBlue: false,
      wsBindDownBlue: false,
      wsOnlineUpBlue: false,
      wsOnlineDownBlue: false,
      wsOfflineUpBlue: false,
      wsOfflineDownBlue: false,
      wsUnBindUpBlue: false,
      wsUnBindDownBlue: false,
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      // 历史分析弹出框
      modalTitle: "",
      visibleHistory: false,
      isShowBtns: [],
    };
  },
  watch: {},
  created() {
    this.queryItems = queryItems;
    this.tableColumns = columns;
    this.dataLoad();

    this.changeColumns();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("PrincipalStatisticsColumnsSet")
      );

      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    //分页页数的改变
    pageNumberChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    //  分页显示条数的变化
    sizeChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    // 实现分页效果
    getShowSource() {
      const data = this.data;
      //后端返回的全部数据
      let keyValue = 0;
      this.total = data.length;
      for (let i = 0; i < this.total; i++) {
        keyValue = keyValue + 1;
        let key = { key: keyValue };
        data[i] = Object.assign(data[i], key);
      }
      this.loading = false;
      let start = this.pageSize * this.current - this.pageSize;
      let end = this.pageSize * this.current;
      let dataSource = data.slice(start, end);
      this.dataSource = dataSource;
      this.$set(this.dataSource, [], dataSource);
    },
    // 加载数据方法 必须为 Promise 对象
    dataLoad(parameter) {
      this.loading = true;
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      getDcountwboss(params).then((res) => {
        // getWscountboss(params).then((res) => {
        this.data = res.data;
        this.getShowSource();
      });
    },
    // 排序
    sortColumn(sort_column, sort_rule, type) {
      console.log(sort_column, sort_rule, type);
      this.queryParam.sort_column = sort_column;
      this.queryParam.sort_rule = sort_rule;
      this.dataLoad();
      // this.$refs.table.refresh();

      if (type) {
        // this[type+'Blue']=true
        this.wsSumUpBlue = false;
        this.wsSumDownBlue = false;
        this.wsSumDownBlue = false;
        this.wsBindUpBlue = false;
        this.wsBindDownBlue = false;
        this.wsOnlineUpBlue = false;
        this.wsOnlineDownBlue = false;
        this.wsOfflineUpBlue = false;
        this.wsOfflineDownBlue = false;
        this.wsUnBindUpBlue = false;
        this.wsUnBindDownBlue = false;
        this[type] = true;
        // this[type+'Blue']=true

        console.log(type);
      }
    },
    //查询触发
    fetchUserAttend(value) {
      this.current = 1;
      this.queryParam = value;
      this.dataLoad();
      // this.$refs.table.refresh();
    },
    // 打开历史分析
    showLinechart(record) {
      console.log(record);
      this.record = record;
      this.visibleHistory = true;
      let w_post = record.w_post == "1" ? "工程管家" : "项目经理";
      this.modalTitle = w_post + "-" + record.wName + "-截止昨天设备使用分析";
    },
    handleOkHistory(e) {
      this.confirmLoading = true;
      setTimeout(() => {
        this.visibleHistory = false;
        // this.confirmLoading = false;
      }, 200);
    },
    handleCancelHistory(e) {
      this.visibleHistory = false;
      // this.$refs.datepickerType.datepickerType = "day";
    },
    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      console.log("ok");
      this.confirmExportLoading = true;
      // setTimeout(() => {
      //   this.confirmExportLoading = false;
      //   this.visibleExport = false;
      // }, 1000);
      const params = {
        w_name: this.queryParam.w_name,
        w_post: this.queryParam.w_post,
        bc_id: this.queryParam.bc_id,
        sort_column: this.queryParam.sort_column,
        sort_rule: this.queryParam.sort_rule,
      };
      ExportDcountwbosse(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "设备统计-按负责人统计.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },

    exportCancel() {
      this.visibleExport = false;
    },
    // 负责工地数   跳转到工地列表    岗位  项目经理或工程管家姓名   工地状态（施工中）
    // 绑定设备工地数   跳转到工地列表  岗位、项目经理或工程管家姓名、工地状态（施工中），绑定状态已绑定
    // 未绑定设备工地数  跳转到工地列表    岗位、项目经理或工程管家姓名、工地状态（施工中），绑定状态未绑定，其他全部或者空

    goToSiteList(type, record, ws_bind_status) {
      // localStorage.setItem("path", "SiteList");
      // const { href } = this.$router.resolve({
      //   path: "/SiteList",
      //   query: {
      //     w_gj_name: record.w_post == 1 ? record.wName : "",
      //     w_name: record.w_post == 2 ? record.wName : "",
      //     d_status: "0",
      //     ws_bind_status: ws_bind_status,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          w_gj_name: record.w_post == 1 ? record.wName : "",
          w_name: record.w_post == 2 ? record.wName : "",
          d_status: "0",
          ws_bind_status: ws_bind_status,
        },
      });
    },
    // 在线设备数    跳转到设备列表    岗位、项目经理或工程管家姓名、绑定状态已绑定，设备状态在线
    // 离线设备数    跳转到设备列表    岗位、项目经理或工程管家姓名、绑定状态已绑定，设备状态离线

    goToDeviceList(record, d_bindstatus, d_status) {
      // console.log(record, d_bindstatus, d_status);
      // localStorage.setItem("path", "DeviceList");
      // const { href } = this.$router.resolve({
      //   path: "/DeviceList",
      //   query: {
      //     d_gj_name: record.w_post == 1 ? record.wName : "",
      //     d_jl_name: record.w_post == 2 ? record.wName : "", //项目经理字段名待定
      //     d_bindstatus: d_bindstatus,
      //     d_status: d_status,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "DeviceList");
      this.$router.push({
        path: "/DeviceList",
        query: {
          d_gj_name: record.w_post == 1 ? record.wName : "",
          d_jl_name: record.w_post == 2 ? record.wName : "", //项目经理字段名待定
          d_bindstatus: d_bindstatus,
          d_status: d_status,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_lineChart {
  color: #1890ff;
  cursor: pointer;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .Title_icon {
    margin-left: 10px;
    // margin-top: -10px;
    padding-top: 10px;
  }
  .Title_up {
    // margin-bottom: -15px;
    margin-bottom: 0%;
    // background-color: pink;
    width: 15px;
    height: 10px;
    line-height: 10px;
  }
  .Title_down {
    // margin-top: -15px;
    width: 15px;
    height: 10px;
    line-height: 10px;
    // background-color: blue;
    // :hover {
    //   // color: aqua;
    // }
  }
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
.cursorPointer {
  cursor: pointer;
}
.numValue {
  // color: blue;
  color: #1890ff;

  .percent {
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.822);
    margin-left: 20px;
    color: #1890ff;
    background-color: #dbedfc;
    padding: 0 10px;
    border-radius: 10px;
  }
}
.table_top {
  display: flex;
  justify-content: space-between;
  color: gray;
}
</style>
